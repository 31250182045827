import React,  {useEffect, useState, useRef} from "react";
import { checkCookies } from 'cookies-next';
import { useRecoilValue } from "recoil";
import { configStoreData } from "../../selectors/store";

import Link from "next/link";
import Search from '../../components/Search/index.jsx';
import HeaderText from '../../components/Home/HeaderText.jsx';
import MenuMobile from "../Layouts/MenuMobile";
import MenuUserZone from "../Layouts/MenuUserZone.jsx";

import { getHomesearchresults } from '../../lib/api/myorders';


const UseFocus = () => {
	const htmlElRef = useRef(null)
	const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

	return [ htmlElRef,  setFocus ]
}

const Header = (props) => {
	
	const storeId = useRecoilValue(configStoreData);
	console.log('IdStore',storeId);
	
	const [showsearchModal, setshowsearchModal] = useState(false);
	const [searchData, setsearchData] = useState([]);	
	const [isStickyHeader, setStickyHeader] = useState(false);
	const [inputRef, setInputFocus] = UseFocus();
	const show = false;


	useEffect(() => {			
		document.getElementById("mobileSearch").addEventListener("input", e => {updateOptions(e.target.value)})		
		document.getElementById("normalSearch").addEventListener("input", e => {updateOptions(e.target.value)})
		
        window.addEventListener('scroll', isSticky);
        return () => {
                window.removeEventListener('scroll', isSticky);
        };
  	},[])

	const isSticky = (e) => {
		let resolution = window.innerWidth;
		const scrollTop = window.scrollY;
		if(props?.menu == "white")
		{
			const header = document.querySelector('.white-header');
			if(scrollTop > 0)
			{
					header?.classList.add('is-sticky-home');
					setStickyHeader(true);
			}
			else {
					header?.classList.remove('is-sticky-home');
					setStickyHeader(false);
			}

		}
		else
		{
			const header = document.querySelector('.black-header');
			if(scrollTop > 0)
			{
					header?.classList.add('is-sticky-home');
					setStickyHeader(true);
			}
			else {
					header?.classList.remove('is-sticky-home');
					setStickyHeader(false);
			}

			}

	};
    const handleMenu = async () => {
		setshowsearchModal(false);
      	let resolution = window.innerWidth;
      	if(resolution < 1200){
        	if(document.querySelector(".personmenu-tab-mob").classList.contains('showmenu'))
        	{
              document.querySelector(".personmenu-tab-mob").classList.remove('showmenu');
        	}
        else {
					document.querySelector("#mainmenuheader").classList.remove('show');
          	document.querySelector(".personmenu-tab-mob").classList.add('showmenu');
		}
      }
    }

	const handleAdvanceSearch = async () => {
		if(showsearchModal == true)
		{
			setshowsearchModal(false);
		}
		else
		{
            setsearchData("")
			setshowsearchModal(true);
			document.querySelector(".personmenu-tab-mob").classList.remove('showmenu');
			document.querySelector("#mainmenuheader").classList.remove('show');
			setTimeout(() => { setInputFocus(); }, 500);
		}
    }
    const handleCancelAdvancedSearch = ()=>{                
        setshowsearchModal(false)
    }

    const debounce = (cb, delay = 250) => {
		let timeout;	  
		return (...args) => {
			clearTimeout(timeout)
		  	timeout = setTimeout(() => {
				cb(...args)
		  	}, delay)
		}
	}

	const updateOptions = debounce(query => {		
		getHomesearchresults(query)			
			.then(response => setsearchData(response))        
	}, 1000)
	
    const MenuEspacios = props?.menuSections?.find(x => x.id == 4);   

    return (
    <>
        
		<div className={props?.menu == "white" ? "white-header": "black-header"}>
        	<div className="container-fluid header_wrap">
          	<div className="small-upper-menu">
            	<div className="small-nav">
              		<ul>
                		<li className="laEditorial"><a href="#">La editorial</a>
							<div className="laEditorialMenu">
								<div className="search-triangle-black"></div>
								<ul className="laEditorialUl">
									<li><Link href="/conocenos">Conócenos</Link></li>
									<li><Link href="/nuestras-tiendas">Nuestras tiendas</Link></li>
									<li><Link href="/nuestras-webs">Nuestras webs</Link></li>
									<li><Link href="/distribucion">Distribución</Link></li>
								</ul>
							</div>
						</li>
                        <li><Link href="/libreros">Libreros</Link></li>
						<li><Link href="/foreign-rights">Foreign rights</Link></li>
						<li><Link href="/ayuda">Ayuda</Link></li>
						<li><Link href="/contacto">Contacto</Link></li>
					</ul>
            	</div>
          	</div>
          	<div className="clearfix"></div>
				<div className="nav-wrap header-wrapper">
					<div className="logo-menu-wrapper nav-inner">
						<div className={isStickyHeader ? "sticky-logo logo":"logo"}>
							<Link href="/" passHref={true}><img src={props?.menu == "white" && !isStickyHeader ? "/assets/images/logo-header-home.png" : "/assets/images/bloque-logo-ecc.svg"} alt="logo"/></Link>
						</div>
						<div className="major-nav">
							<nav className="navbar navbar-expand-md postion">
								<button className="navbar-toggler button " type="button" data-toggle="collapse" data-target="#collapsibleNavbar3">
									<span className="navbar-toggler-icon"></span>
								</button>
								<div className="clearfix"></div>
								<div className="collapse navbar-collapse deco-wrap blackheader " id="collapsibleNavbar3">
									<ul className="navbar-nav deco">
										<li className="nav-item nave-wrap drop-down">
											<a  href="#">Novedades</a>
											<div className="spacer-2">
												<ul className="novedades arrownav drop">
													<div className="triangle triangle-4"></div>
													<li><Link href="/novedades/esta-semana">A la venta esta semana</Link></li>
													<li><Link href="/comunicados">Comunicados</Link></li>
												</ul>
											</div>
										</li>
										{storeId == 1 &&
											<li className=" nav-item cata-wrap drop-down">
												<a href="#">Catálogo</a>
												<div className="spacer-2cata">
													<ul className="cata arrownavcata drop">
														<div className="trianglecata triangle-4cata"></div>
														<li><Link href="/sellos/dc-comics">DC Comics</Link></li>
														<li><Link href="/sellos/manga">Manga</Link></li>																								
														<li><Link href="/sellos/komodo">Kodomo</Link></li>
														<li><Link href="/sellos/ecc-comics">ECC Cómics</Link></li>
													</ul>
												</div>
											</li>
										}
										{storeId == 9 &&
											<li className=" nav-item cata-wrap drop-down">
												<a href="#">Menú TC</a>
												<div className="spacer-2cata">
													<ul className="cata arrownavcata drop">
														<div className="trianglecata triangle-4cata"></div>
														<li><Link href="/catalogo/marvel/prueba-categoria-2-tc">Pruebas Ecc Cómics</Link></li>
														<li><Link href="/sellos/manga">Manga</Link></li>																								
														<li><Link href="/sellos/komodo">Kodomo</Link></li>
														<li><Link href="/sellos/ecc-comics">ECC Cómics</Link></li>
													</ul>
												</div>
											</li>
										}
										<li className="nav-item actual-wrap"><Link href="/actualidad">Actualidad</Link>
										</li>
										<li className="nav-item espac-wrap drop-down">
											<a href="#">Espacios</a>
											<div className="spacer-2espac">
												<ul className="espac arrownavespac drop">
													<div className="triangleespac triangle-4espac"></div>
													<li>
														<ul className="b1">
                                                            {MenuEspacios?.childMenu?.filter(x=> x.column == 'Left').sort((a,b) => a.order-b.order).map((menu, i) => {return (<li key={`espacios${i}`}><Link href={`${menu.href}`}><a target={menu.newWindow ==1 ? '_blank' :'_self'} >{`${menu.text}`}</a></Link></li>)})}														
														</ul>
													</li>
													<li>
														<ul>
                                                            {MenuEspacios?.childMenu?.filter(x=> x.column == 'Right' || x.column == '' ).sort((a,b) => a.order-b.order).map((menu, i) => {return (<li key={`espaciosb${i}`}><Link href={`${menu.href}`}><a target={menu.newWindow ==1 ? '_blank' :'_self'} >{`${menu.text}`}</a></Link></li>)})}    															
														</ul>
													</li>
												</ul>
											</div>
										</li>
										<li className="nav-item "><Link href="/merchandise">Merchandise</Link></li>
										<li className="nav-item "><Link href="/agenda">Agenda</Link></li>
									</ul>
								</div>
							</nav>
						</div>
					</div>
					<div className="social-media-wrapper">
						<div className="social3">
							<MenuUserZone handleMenu={handleMenu} handleAdvanceSearch={handleAdvanceSearch} mobile={false}/>
						</div>
						<div className="social2">
							<ul className="navbar-nav">
								<li  className="nav-item"><a rel="noreferrer"  target="_blank" href="https://www.facebook.com/ECCEdiciones"><i className="fab fa-facebook-square socialico5" aria-hidden="true"></i></a></li>
								<li  className="nav-item"><a rel="noreferrer"  target="_blank" href="https://twitter.com/eccediciones"><i className="fab fa-x-twitter socialico5" aria-hidden="true"></i></a></li>
								<li  className="nav-item"><a rel="noreferrer"  target="_blank" href="https://instagram.com/eccediciones"><i className="fab fa-instagram socialico5" aria-hidden="true"></i></a></li>
								<li  className="nav-item"><a rel="noreferrer"  target="_blank" href="https://www.youtube.com/user/CanalECCEdiciones"><i className="fab fa-youtube socialico5" aria-hidden="true"></i></a></li>
							</ul>
						</div>
					</div>
				</div>

        	</div>
      	</div>
		<div className={props?.menu == "white" ? "container-fluid white-border" : "container-fluid black-border"}></div>
		<div className={props?.menu == "white" ? "home-search": ""}>
			<div className={isStickyHeader ? "sticky-search": ""}>
				<Search searchModal={showsearchModal} searchData={searchData} handleCancel={handleCancelAdvancedSearch}  focusInput = {inputRef}/>
			</div>
		</div>
    	{props?.page == "home" && <HeaderText text={props?.textData}/>}
		<div className={isStickyHeader ? "is-sticky-menumobile" :""}>
			<div className={props?.page == "home" ? "home-menu": "common-menu"}>
				<MenuMobile page={props?.menu== "white" ? "home-menu": "common-menu"} setshowsearchModal={handleCancelAdvancedSearch} menuSections={props.menuSections} ></MenuMobile>
			</div>
		</div>
		

		<div className="personmenu-tab-mob">
		<MenuUserZone handleMenu={handleMenu} handleAdvanceSearch={handleAdvanceSearch}  mobile={true}/>
		</div>		
	</>
  );
};

export default  React.memo(Header);
